<script setup>
import {useInstanceStore} from "@/store/backendInstances";
import {computed, onMounted, ref} from "vue";
import {inputRequiredRule} from "@/composables/inputRules";
const instanceStore = useInstanceStore()

const props = defineProps({
  globalUniqueIdentifier: {type: String, required: true},
  modelValue: {type: String||null, default: null},
})
defineEmits(["update:modelValue"])
const rules = [inputRequiredRule]

const checkbox = ref(null)

const currentEULA = computed(() => instanceStore.eulas[props.globalUniqueIdentifier]?.['current'])

// eslint-disable-next-line no-unused-vars
const isValid = computed(() => checkbox.value?.isValid)
// eslint-disable-next-line no-unused-vars
const isValidating = computed(() => checkbox.value?.isValidating)
// eslint-disable-next-line no-unused-vars
const validate = () => checkbox.value?.validate()

onMounted(async () => {
  if(!currentEULA.value) await instanceStore.getCurrentEula(props.globalUniqueIdentifier)
})
</script>

<template>
  <v-checkbox
    v-if="currentEULA"
    ref="checkbox"
    :rules="rules"
    :model-value="modelValue !== null"
    @update:model-value="$emit('update:modelValue', $event === true ? currentEULA.version : null)"
  >
    <template #label>
      {{ $t("eula.accept_text") }}&nbsp; <a
        :href="currentEULA.url"
        target="_blank"
        @click.stop
      > {{ $t("eula.eula") }} </a>
    </template>
  </v-checkbox>
</template>
<style scoped>

</style>
