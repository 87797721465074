// Utilities
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { PiniaSharedState } from 'pinia-shared-state';
import {markRaw} from 'vue'
import router from '../router'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
pinia.use(PiniaSharedState({enable: false,}))
pinia.use(({store}) => {store.router = markRaw(router)})
export default pinia
