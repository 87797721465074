<script setup>

defineEmits(["close"])
function goToHelpDesk() {
  window.open("https://vetvise.atlassian.net/servicedesk/customer/portal/8/group/21/create/66", "_blank")
}
</script>

<template>
  <v-layout-card>
    <v-card-title>
      {{ $t("welcome.title") }}
    </v-card-title>
    <v-card-text>
      {{ $t("welcome.text") }}
    </v-card-text>

    <v-card-actions class="justify-end">
      <v-btn
        variant="outlined"
        @click="goToHelpDesk"
      >
        {{ $t("welcome.need_help") }}
      </v-btn>
      <v-btn
        color="success"
        variant="flat"
        class="rounded-pill"
        @click="$router.push({name: 'Add Instance'}); $emit('close')"
      >
        {{ $t("general_interface.buttons.continue") }}
      </v-btn>
    </v-card-actions>
  </v-layout-card>
</template>

<style scoped>

</style>
