/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import * as components from 'vuetify/components'
//import * as labsComponents from 'vuetify/labs/components'

// Composables
import {createVuetify} from 'vuetify'
import {VCard} from "vuetify/components";
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  aliases: {
    VLayoutCard: VCard
  },
  defaults: {
    VTextField: {
      rounded: true,
    },
    VAlert: {
      style: "border-radius: 32px"
    },
    VSwitch: {
      falseIcon:"mdi-close",
      trueIcon:"mdi-check",
      color: "primary",
    },
    VSelect: {
      rounded: true,
    },
    VAutocomplete: {
      rounded: true,
    },
    VSheet: {
      elevation: 2,
      variant: "elevated",
      style: "border-radius: 32px",
    },
    VLayoutCard: {
      elevation: 2,
      variant: "elevated",
      style: "border-radius: 32px;",
      class: "pa-2 ma-2"
    },
    VBtn: {
      class: "rounded-pill",
    }
  },
  display: {
    mobileBreakpoint: 800,
  },
  components: {
    ...components,
    //...labsComponents,
  },
  theme: {
    themes: {
      light: {
        "colors": {
          "background": "#C2CADD",
          "surface": "#FFFFFF",
          "primary": "#254C63",
          "secondary": "#9BCCE8",
          "error": "#F44336",
          "info": "#54B0E4",
          "success": "#7EB75C",
          "warning": "#E7AB00"
        }
      },
      dark: {
        "colors": {
          "background": "#121212",
          "surface": "#212121",
          "primary": "#9BCCE8",
          "secondary": "#03DAC5",
          "error": "#F44336",
          "info": "#54B0E4",
          "success": "#7EB75C",
          "warning": "#E7AB00"
        }
      }
    },
  },
})
