/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue'
import EmojiPicker from 'vue3-emoji-picker'
import {registerSW} from 'virtual:pwa-register'

// import css
import 'vue3-emoji-picker/css'

import './default-style.css'

// Composables
import {createApp} from 'vue'

// Plugins
import {registerPlugins} from '@/plugins'
import {useMiscellaneousStore} from "@/store/miscellaneous";
import {useLogsStore} from "@/store/logs.js";

const app = createApp(App)

registerPlugins(app)

app.component("EmojiPicker", EmojiPicker)

const backgroundServiceWorkerUpdateInterval = 1000 * 60 * 60
const updateSW = registerSW({
  onNeedRefresh() {
    const miscellaneousStore = useMiscellaneousStore()
    miscellaneousStore.setUpdateAvailable(updateSW)
  }, onRegisteredSW(_, swRegistration) {
    swRegistration && setInterval(() => {
      swRegistration.update().then().catch(e =>
        useLogsStore().addLogEntry({
          message: "Failed to update service worker",
          error: e,
          tag: "main",
          globalUniqueIdentifier: null,
          level: "ERROR"
        }))
    }, backgroundServiceWorkerUpdateInterval)
  },
  onOfflineReady() {
  },
})

const channel = new BroadcastChannel('force-refresh')
channel.onmessage = () => {
  window.location.href = window.location.origin
}
useLogsStore().addLogEntry({message: "New Session Started!", tag: "main", globalUniqueIdentifier: "null", level: "INFO"})
app.config.errorHandler = (err, vm, info) => {
  useLogsStore().addLogEntry({message: "Render-Error " + info, tag: "RENDER", globalUniqueIdentifier: null, level: "ERROR", error: err})
}

app.mount('#app')
