/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import { loadFonts } from './webfontloader'
import vuetify from './vuetify'
import router from '../router'
import pinia from '../store'
import i18n from './i18n'
import Toast, { POSITION } from "vue-toastification"
import VueKonva from 'vue-konva'
import "vue-toastification/dist/index.css";
import VueApexCharts from "vue3-apexcharts";
import BackendAPI from "@/BackendAPI";
import VueVideoPlayer from "@videojs-player/vue";
import 'video.js/dist/video-js.css'

export function registerPlugins (app) {
  loadFonts()
  app
    .use(vuetify)
    .use(pinia)
    .use(router)
    .use(Toast, {position: POSITION.BOTTOM_RIGHT, transition: "Vue-Toastification__slideBlurred", pauseOnFocusLoss: false})
    .use(VueKonva)
    .use(i18n)
    .use(VueApexCharts)
    .use(BackendAPI)
    .use(VueVideoPlayer)
}
